import React, { Component } from 'react';
import { connect } from 'react-redux';

export const ROUTE_TYPES = {
  SUBSCRIBER: 'subscriber',
  USER: 'user',
  ADMIN: 'admin',
  TERMS_CONDITIONS: 'terms-conditions',
};

export default (WrappedComponent, routeType) => {
  class ConnectedComponent extends Component {
    componentDidMount() {
      const { history, loggedIn, loginType } = this.props;
      if (!loggedIn || routeType !== loginType) {
        history.push('/subscribers/unauthenticated');
      }
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ login: { loggedIn, loginType, isFreeTrial } }) => ({
    loggedIn,
    loginType,
    isFreeTrial
  });

  return connect(mapStateToProps)(ConnectedComponent);
};
