import React, { useState, useEffect } from 'react';
import { Footer } from '../../components'; // 确保引入 Footer 组件
import { getWordpressPage } from '../../services/wordpressService';

const TermsConditions = () => {

    const [terms, setTerms] = useState('');

    useEffect(() => {
        getWordpressPage(process.env.REACT_APP_WP_SUBSCRIBER_TERMS_ID).then(response => {
            setTerms(response.data.content.rendered);
        });
    }, []);

    return (
        <div>
            {terms ? (
                <div style={{padding: '20px', maxWidth: '1200px', margin: '60px auto 0 auto', width: '100%', wordWrap: 'break-word'}}>
                    <h1 style={{textAlign: 'center', marginBottom: '20px'}}>Terms and Conditions</h1>
                    <div dangerouslySetInnerHTML={{ __html: terms }} />
                </div>
            ) : (
                <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Loading...</div>
            )}
            <Footer />
        </div>
    );
};

export default TermsConditions;
