import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'reactstrap';
import {Footer, Section} from '../../components';
import Menu from '../../components/subscriber/Menu';
import {getPaymentInfo} from '../../actions/subscriber/getPaymentInfo';
import {paymentInfoResult, savePaymentInfo, payArrears} from '../../services/subscriber/paymentsService';
import InfoModal from './PaymentInfoModal';
import UpgradePremiumModal from './Company/UpgradePremiumModal'
import { paymentMethods } from '../../assets/img';

class Payments extends Component {

    constructor(props) {

        super(props);

        this.state = {

            accountStatus: "cc_recurring",
            ccRecurring: "Recurring 1st",
            paymentOption: "1",

            id: "",
            companyId: "",
            cardNumber: "",
            cardMonth: "01",
            cardYear: "2025",
            cardCvv: "",

            firstName: "",
            lastName: "",
            billingAddress: "",
            billingCity: "",
            billingCountry: "USA",
            billingState: "AL",
            billingZip: "",
            email: "",

            isClick: true,
            title: "",
            content: "",
            arrearsId: "",
            busTypeOptions: [{key: 1, value: 2, name: "2554"}, {key: 3, value: 4, name: "222"}],
            busTypeId: {"busTypeId": 1},
            canClickPay: true,

            selectValue: {
                amenitiesOptions: "",
                busTypeOptions: "",
                values: "",
                errorMessage: "",
                modalRef: "",
                modalVisible: "",
                onButtonSubmit: "",
                onFormSubmit: "",
                toggleModal: "",
                busTypeId: "sdf",
                touched: ""
            },
            upgradePremiumModalVisible: false,
            termsAccepted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    toggleModal = modal => this.setState(prevState => ({[modal]: !prevState[modal]}));

    componentWillMount = () => {

        this.props.getPaymentInfo();
    }

    componentDidMount = () => {

        /*        let ANS_customer_id = "0107e6ee-5a2e-43bb-b1e8-9d7d1ae0e68e";
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.src = 'https://verify.authorize.net/anetseal/seal.js';
                document.head.appendChild(script);*/

        let cardButton = document.getElementById('card-button');
        let companyId = this.props.companyId;
        let self = this;        // 绑定提交方法
        cardButton.addEventListener('click', function (ev) {

            if (self.state.isClick == true) {
                let validInfo = self.validateForm(self);

                if (validInfo === '') {
                    cardButton.classList.remove("btn-secondary");
                    cardButton.style.cursor = "wait";
                    cardButton.style.backgroundColor = "#6c757d";
                    cardButton.style.borderColor = "#6c757d";
                    self.setState({isClick: false});

                    let data = {
                        cardNumber: self.state.cardNumber
                        , cardMonth: self.state.cardMonth
                        , cardYear: self.state.cardYear
                        , cardCvv: self.state.cardCvv
                        , firstName: self.state.firstName
                        , lastName: self.state.lastName
                        , billingAddress: self.state.billingAddress
                        , billingCity: self.state.billingCity
                        , billingCountry: self.state.billingCountry
                        , billingState: self.state.billingState
                        , billingZip: self.state.billingZip
                        , email: self.state.email
                        , companyId: companyId
                        , accountStatus: 'cc_recurring'
                        , ccRecurring: 'Recurring 1st'
                        , paymentOption: self.state.paymentOption
                    }

                    savePaymentInfo(data).then(function (response) {

                        if (response.data.status.code == 0) {
                            if (response.data.result.arrearsStatus) {

                                self.setState({
                                    title: "Save Success.",
                                    content: "Do you want to pay the outstanding balance using your new payment information now?",
                                    arrearsId: response.data.result.arrearsId
                                });
                            } else {
                                self.setState({title: "Payments", content: "Save Success.", arrearsId: false});
                            }
                            self.toggleModal('loginInformationModalVisible')
                        } else {

                            if(response.data.status.msg === 'The credit card number is invalid.'){
                                document.getElementById("card-number").style.backgroundColor = "#fbced2"

                                alert('The credit card number you entered is invalid, please re-enter the correct credit card number. Should you continue to have trouble saving your credit card, Please call us at 1-703-838-2955 and we will be happy to help you!');
                            }else {
                                self.setState({title: "", content: "Your credit card has been declined for authorization, please provide new credit card. Should you continue to have trouble saving your credit card, Please call us at 1-703-838-2955 and we will be happy to help you!"});
                                self.toggleModal('loginInformationModalVisible')

                            }

                        }
                        cardButton.classList.add("btn-secondary");
                        cardButton.style.cursor = "";
                        cardButton.style.backgroundColor = "";
                        cardButton.style.borderColor = "";
                        self.setState({isClick: true});
                    });
                } else {
                    alert(validInfo)
                }
            }
        });

        paymentInfoResult(companyId).then(function (result) {

            let data = {
                cardNumber: result.data.cardNumber
                , cardMonth: result.data.cardMonth ? result.data.cardMonth : self.state.cardMonth
                , cardYear: result.data.cardYear ? result.data.cardYear : self.state.cardYear
                , cardCvv: result.data.cardCvv
                , firstName: result.data.firstName
                , lastName: result.data.lastName
                , billingAddress: result.data.billingAddress
                , billingCity: result.data.billingCity
                , billingCountry: result.data.billingCountry ? result.data.billingCountry : self.state.billingCountry
                , billingState: result.data.billingState ? result.data.billingState : self.state.billingState
                , billingZip: result.data.billingZip
                , email: result.data.email
                , companyId: companyId
                , accountStatus: result.data.accountStatus ? result.data.accountStatus : self.state.accountStatus
                , ccRecurring: result.data.ccRecurring ? result.data.ccRecurring : self.state.ccRecurring
                , paymentOption: result.data.paymentOption ? result.data.paymentOption : self.state.paymentOption
            }

            self.setState(data);
        })
    };


    handleChange(event) {

        let attr = event.target.getAttribute("valueAttr");
        let state = {};
        if (event.target.type === 'checkbox') {
            state[attr] = event.target.checked; // 使用 checked 属性
        } else {
            state[attr] = event.target.value;
        }
        this.setState(state);
        if (event.target.value != "") {
            event.target.style.backgroundColor = "white";
        }
    }

    handleCountryChange(event) {

        let country = event.target.value;
        this.setState({"billingCountry": country});
        let options = document.getElementById('billing-state').childNodes;
        let hasSelected = false;
        for (let i = 0; i < options.length; i++) {
            if (options[i].getAttribute("country") == country) {
                if (hasSelected == false) {
                    this.setState({"billingState": options[i].value});
                    hasSelected = true
                }
                options[i].style.display = "";
            } else {
                options[i].selected = false;
                options[i].style.display = "none";
            }
        }
    }

    openAuth() {
        window.open('https://verify.authorize.net/anetseal/?pid=0107e6ee-5a2e-43bb-b1e8-9d7d1ae0e68e&amp;rurl=https%3A//www.busrates.com/subscribers/payments',
            'AuthorizeNetVerification',
            'width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes');
    }

    validateCreditCard = (s) => {

        if (s.length === 4 && /^[0-9]*$/.test(s)){
            return true;
          }
        // remove non-numerics
        var v = '0123456789',
            w = '',
            i, j, k, m, c, a, x;

        for (i = 0; i < s.length; i++) {
            x = s.charAt(i);

            if (v.indexOf(x, 0) !== -1) {
                w += x;
            }
        }
        // validate number
        j = w.length / 2;
        k = Math.floor(j);
        m = Math.ceil(j) - k;
        c = 0;

        for (i = 0; i < k; i++) {
            a = w.charAt(i * 2 + m) * 2;
            c += a > 9 ? Math.floor(a / 10 + a % 10) : a;
        }

        for (i = 0; i < k + m; i++) {
            c += w.charAt(i * 2 + 1 - m) * 1;
        }

        return c % 10 === 0;
    }

    validateForm(self) {

        let flag = true;
        let validNumber = this.validateCreditCard(self.state.cardNumber);
        let missionFields = '';
        if (self.state.cardNumber == "" || !validNumber) {

            document.getElementById("card-number").style.backgroundColor = "#fbced2"
            return 'The credit card number you entered is invalid, please re-enter the correct credit card number. Should you continue to have trouble saving your credit card, Please call us at 1-703-838-2955 and we will be happy to help you!';
        }
        if (self.state.cardCvv == "" || !(/^[0-9]*$/.test(self.state.cardCvv))) {

            document.getElementById("card-cvv").style.backgroundColor = "#fbced2"
            missionFields +='Security Code(CVV),'
            flag = false;
        }
        if (self.state.firstName == "") {
            document.getElementById("first-name").style.backgroundColor = "#fbced2"
            missionFields +='First Name,'
            flag = false;
        }
        if (self.state.lastName == "") {
            document.getElementById("last-name").style.backgroundColor = "#fbced2"
            missionFields +='Last Name,'
            flag = false;
        }
        if (self.state.companyName == "") {
            document.getElementById("company-name").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if (self.state.billingAddress == "") {
            document.getElementById("billing-address").style.backgroundColor = "#fbced2"
            missionFields+="Billing Address,"
            flag = false;
        }
        if (self.state.billingCity == "") {
            document.getElementById("billing-city").style.backgroundColor = "#fbced2"
            missionFields+="Billing City,"
            flag = false;
        }
        if (self.state.billingCountry == "") {
            document.getElementById("billing-country").style.backgroundColor = "#fbced2"
            missionFields+="Billing Country,"

            flag = false;
        }
        if (self.state.billingState == "billing-state") {
            document.getElementById("billing-state").style.backgroundColor = "#fbced2"
            missionFields +="Billing State,"
            flag = false;
        }
        if (self.state.billingZip == "") {
            document.getElementById("billing-zip").style.backgroundColor = "#fbced2"
            missionFields+="Billing Zip,"
            flag = false;
        }
        if (self.state.email == "") {
            document.getElementById("email").style.backgroundColor = "#fbced2"
            missionFields+='Email Address,'
            flag = false;
        }
        if(!flag){
            missionFields = missionFields.substr(0,missionFields.length-1);
            return 'Your credit card information is incomplete, please fill in the following field:  '+missionFields+'.Should you continue to have trouble saving your credit card, Please call us at 1-703-838-2955 and we will be happy to help you!';
        }

        if (!self.state.termsAccepted) {
            return 'You must accept the terms and conditions to continue.';
        }

        return '';


    }


    onButtonGo = values => {

        if(this.state.arrearsId) {
            let payButton = document.getElementById('pay-button');
            if (this.state.canClickPay == true) {

                this.setState({canClickPay: false});
                let data = {companyId: this.props.companyId, paymentOrigin: "1"}
                let self = this;

                payButton.classList.remove("btn-secondary");
                payButton.style.cursor = "wait";
                payButton.style.backgroundColor = "#6c757d";
                payButton.style.borderColor = "#6c757d";
                payArrears(data).then(function (response) {

                    payButton.classList.remove("btn-secondary");
                    payButton.style.cursor = "wait";
                    payButton.style.backgroundColor = "#6c757d";
                    payButton.style.borderColor = "#6c757d";
                    if (response.data.result == true) {

                        self.setState({title: "", content: "Pay Success."});
                    } else {

                        self.setState({title: "", content: "Pay Fail!"});
                    }
                    payButton.classList.add("btn-secondary");
                    payButton.style.cursor = "";
                    payButton.style.backgroundColor = "";
                    payButton.style.borderColor = "";
                    self.setState({canClickPay: true});
                });
            }
        } else {
            if(this.props.isFreeTrial && this.props.isUpgradePremium){
                this.setState({upgradePremiumModalVisible: true,loginInformationModalVisible: false})
            }else{
                this.props.history.push('/subscribers/listings');
            }
        }
    };

    render(formProps) {
        return (
            <div className="pt-5">
                <Section>
                    <Row>
                        <Col xs="12" className="text-center">
                            <h1 className="pb-3">Payments</h1>
                        </Col>
                    </Row>

                    <Row>
                        <Menu history={this.props.history}/>
                        <Col xs="9">
                            <div className="checkout">
                                <div>
                                    <div>
                                        <div style={{"margin": "10px 0"}}>
                                            <span style={{
                                                "width": "165px",
                                                "display": "inline-block"
                                            }}>Account Status *</span>
                                            <select style={{"width": "200px", "display": "initial"}}
                                                    id="account-status" valueAttr="accountStatus"
                                                    value={this.state.accountStatus} onChange={this.handleChange}
                                                    className="form-control">
                                                <option value="cc_recurring" selected>CC Recurring</option>
                                            </select>
                                        </div>
                                        <div style={{"margin": "10px 0"}}>
                                            <span style={{
                                                "width": "165px",
                                                "display": "inline-block"
                                            }}>CC Recurring *</span>
                                            <select style={{"width": "200px", "display": "initial"}}
                                                    id="ccrecurring_selectedvalue" valueAttr="ccRecurring"
                                                    value={this.state.ccRecurring}
                                                    onChange={this.handleChange}
                                                    className="form-control">
                                                <option value="Recurring 1st" selected>Recurring 1st</option>
                                                {/*<option value=">Recurring 4th">Recurring 4th</option>*/}
                                                {/*<option value="Recurring 7th">Recurring 7th</option>*/}
                                                {/*<option value="Recurring 10th">Recurring 10th</option>*/}
                                                {/*<option value="Recurring 13th">Recurring 13th</option>*/}
                                                {/*<option value="Recurring 15th">Recurring 15th</option>*/}
                                                {/*<option value="Recurring 18th">Recurring 18th</option>*/}
                                                {/*<option value="Recurring 19th">Recurring 19th</option>*/}
                                                {/*<option value="Recurring 21st">Recurring 21st</option>*/}
                                                {/*<option value="Recurring 23rd">Recurring 23rd</option>*/}
                                                {/*<option value="Recurring 25th">Recurring 25th</option>*/}
                                                {/*<option value="Recurring 27th">Recurring 27th</option>*/}
                                                {/*<option value="Recurring 29th">Recurring 29th</option>*/}
                                            </select>
                                            <span style={{
                                                "width": "150px",
                                                "display": "inline-block",
                                                "marginLeft": "10px"
                                            }}>Payment Option *</span>
                                            <select
                                                style={{"width": "170px", "display": "initial", "marginLeft": "10px"}}
                                                id="payment-option" valueAttr="paymentOption"
                                                value={this.state.paymentOption} onChange={this.handleChange}
                                                className="form-control">
                                                <option value="1" selected>Monthly</option>
                                                <option value="2">Quarterly</option>
                                                <option value="3">Six Months</option>
                                                <option value="4">Annual</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span style={{"width": "165px", "display": "inline-block"}}>Card Number *</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="card-number" valueAttr="cardNumber" value={this.state.cardNumber}
                                               style={{"width": "100%"}}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <span style={{"width": "150px", "display": "inline-block", "marginLeft": "10px"}}>Expiration date *</span>
                                    {/*<input id="first-name" placeholder={'Month'}  style={{"width": "80px"}}/>
                                    <input id="first-name"  placeholder={'year'} style={{"width": "80px", "marginLeft":"10px"}}/>*/}
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <select style={{"width": "80px", "marginLeft": "10px", "display": "initial"}}
                                                id="card-month" valueAttr="cardMonth" value={this.state.cardMonth}
                                                onChange={this.handleChange} className="form-control">
                                            <option value="01" selected>01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                        <select style={{"width": "80px", "marginLeft": "10px", "display": "initial"}}
                                                id="card-year" valueAttr="cardYear" value={this.state.cardYear}
                                                onChange={this.handleChange} className="form-control">
                                            <option value="2025" selected>2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                            <option value="2032">2032</option>
                                            <option value="2033">2033</option>
                                            <option value="2034">2034</option>
                                            <option value="2035">2035</option>
                                            <option value="2036">2036</option>
                                            <option value="2037">2037</option>
                                            <option value="2038">2038</option>
                                            <option value="2039">2039</option>
                                            <option value="2040">2040</option>
                                        </select>
                                        {/*<select onChange={this.selectChange} value = {this.state.select.value}> {
                                        this.state.select.ls.map((item,i) => { return ( <option key={"op"+i} index={i} value={item.value}>{item.text}</option>)})}
                                    </select>*/}
                                    </div>
                                </div>
                                <div>
                                    <span
                                        style={{"width": "165px", "display": "inline-block"}}>Security Code(CVV)*</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="card-cvv" valueAttr="cardCvv" maxLength={'4'}
                                               style={{"width": "100%"}}
                                               value={this.state.cardCvv} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div>
                                    <span style={{"width": "165px", "display": "inline-block"}}>First Name* </span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="first-name" valueAttr="firstName" value={this.state.firstName}
                                               style={{"width": "100%"}}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <span style={{"width": "150px", "display": "inline-block", "marginLeft": "10px"}}>Last Name*</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="last-name" valueAttr="lastName" value={this.state.lastName}
                                               style={{"width": "100%"}}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div>
                                    <span style={{"width": "165px", "display": "inline-block"}}>Billing Address*</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="billing-address" valueAttr="billingAddress"
                                               style={{"width": "100%"}}
                                               value={this.state.billingAddress} onChange={this.handleChange}/>
                                    </div>
                                    <span style={{"width": "150px", "display": "inline-block", "marginLeft": "10px"}}>Billing City*</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="billing-city" valueAttr="billingCity" value={this.state.billingCity}
                                               style={{"width": "100%"}}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div>
                                    <span style={{"width": "165px", "display": "inline-block"}}>Billing Country*</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        {/*<input id="billing-country" valueAttr="billingCountry" value={this.state.billingCountry} onChange={this.handleChange}/>*/}
                                        <select id="billing-country" style={{
                                            "width": "196px",
                                            "display": "initial",
                                            "margin": "10px 0 20px 0"
                                        }} onChange={this.handleCountryChange}
                                                value={this.state.billingCountry} className="form-control"
                                                valueAttr="billingCountry">
                                            <option value="USA" selected>USA</option>
                                            <option value="CA">CA</option>
                                        </select>
                                    </div>
                                    <span style={{"width": "150px", "display": "inline-block", "marginLeft": "10px"}}>Billing State*</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        {/*<input id="billing-state" valueAttr="billingState" value={this.state.billingState} onChange={this.handleChange}/>*/}
                                        <select id="billing-state" style={{"width": "196px", "display": "initial"}}
                                                valueAttr="billingState"
                                                value={this.state.billingState} className="form-control"
                                                onChange={this.handleChange}>
                                            <option style={{"display": "none"}} country="CA" value="Ontario">Ontario
                                            </option>
                                            <option style={{"display": "none"}} country="CA" value="Quebec">Quebec
                                            </option>
                                            <option style={{"display": "none"}} country="CA"
                                                    value="British Columbia">British Columbia
                                            </option>
                                            <option style={{"display": "none"}} country="CA" value="Alberta">Alberta
                                            </option>
                                            <option style={{"display": "none"}} country="CA" value="Manitoba">Manitoba
                                            </option>
                                            <option style={{"display": "none"}} country="CA"
                                                    value="Saskatchewan">Saskatchewan
                                            </option>
                                            <option style={{"display": "none"}} country="CA" value="Nova Scotia">Nova
                                                Scotia
                                            </option>
                                            <option style={{"display": "none"}} country="CA" value="New Brunswick">New
                                                Brunswick
                                            </option>
                                            <option style={{"display": "none"}} country="CA"
                                                    value="Newfoundland and Labrador">Newfoundland and Labrador
                                            </option>
                                            <option style={{"display": "none"}} country="CA"
                                                    value="Prince Edward Island">Prince Edward Island
                                            </option>
                                            <option style={{"display": "none"}} country="CA"
                                                    value="Northwest Territories">Northwest Territories
                                            </option>
                                            <option style={{"display": "none"}} country="CA" value="Nunavut">Nunavut
                                            </option>
                                            <option style={{"display": "none"}} country="CA" value="Yukon">Yukon
                                            </option>
                                            <option country="USA" value="AL">AL</option>
                                            <option country="USA" value="AK">AK</option>
                                            <option country="USA" value="AS">AS</option>
                                            <option country="USA" value="AZ">AZ</option>
                                            <option country="USA" value="AR">AR</option>
                                            <option country="USA" value="CA">CA</option>
                                            <option country="USA" value="CO">CO</option>
                                            <option country="USA" value="CT">CT</option>
                                            <option country="USA" value="DE">DE</option>
                                            <option country="USA" value="DC">DC</option>
                                            <option country="USA" value="FM">FM</option>
                                            <option country="USA" value="FL">FL</option>
                                            <option country="USA" value="GA">GA</option>
                                            <option country="USA" value="GU">GU</option>
                                            <option country="USA" value="HI">HI</option>
                                            <option country="USA" value="ID">ID</option>
                                            <option country="USA" value="IL">IL</option>
                                            <option country="USA" value="IN">IN</option>
                                            <option country="USA" value="IA">IA</option>
                                            <option country="USA" value="KS">KS</option>
                                            <option country="USA" value="KY">KY</option>
                                            <option country="USA" value="LA">LA</option>
                                            <option country="USA" value="ME">ME</option>
                                            <option country="USA" value="MH">MH</option>
                                            <option country="USA" value="MD">MD</option>
                                            <option country="USA" value="MA">MA</option>
                                            <option country="USA" value="MI">MI</option>
                                            <option country="USA" value="MN">MN</option>
                                            <option country="USA" value="MS">MS</option>
                                            <option country="USA" value="MO">MO</option>
                                            <option country="USA" value="MT">MT</option>
                                            <option country="USA" value="NE">NE</option>
                                            <option country="USA" value="NV">NV</option>
                                            <option country="USA" value="NH">NH</option>
                                            <option country="USA" value="NJ">NJ</option>
                                            <option country="USA" value="NM">NM</option>
                                            <option country="USA" value="NY">NY</option>
                                            <option country="USA" value="NC">NC</option>
                                            <option country="USA" value="ND">ND</option>
                                            <option country="USA" value="MP">MP</option>
                                            <option country="USA" value="OH">OH</option>
                                            <option country="USA" value="OK">OK</option>
                                            <option country="USA" value="OR">OR</option>
                                            <option country="USA" value="PW">PW</option>
                                            <option country="USA" value="PA">PA</option>
                                            <option country="USA" value="PR">PR</option>
                                            <option country="USA" value="RI">RI</option>
                                            <option country="USA" value="SC">SC</option>
                                            <option country="USA" value="SD">SD</option>
                                            <option country="USA" value="TN">TN</option>
                                            <option country="USA" value="TX">TX</option>
                                            <option country="USA" value="UT">UT</option>
                                            <option country="USA" value="VT">VT</option>
                                            <option country="USA" value="VI">VI</option>
                                            <option country="USA" value="VA">VA</option>
                                            <option country="USA" value="WA">WA</option>
                                            <option country="USA" value="WV">WV</option>
                                            <option country="USA" value="WI">WI</option>
                                            <option country="USA" value="WY">WY</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <span style={{"width": "165px", "display": "inline-block"}}>Billing Zip*</span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="billing-zip" valueAttr="billingZip" value={this.state.billingZip}
                                               style={{"width": "100%"}}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <span style={{"width": "150px", "display": "inline-block", "marginLeft": "10px"}}>Email Address </span>
                                    <div style={{"width": "200px", "display": "inline-block"}}>
                                        <input id="email" valueAttr="email" value={this.state.email}
                                               style={{"width": "100%"}}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div style={{"marginTop": "20px", "marginBottom": "20px", "display": "flex", "alignItems": "center"}}>
                                    <div style={{"marginRight": "10px"}}>Currently supporting:</div>
                                    <img src={paymentMethods} alt="Payment Methods" />
                                </div>
                                <div style={{"marginTop": "20px", "marginBottom": "20px"}}>
                                    <span className="pr-3">
                                        I read and agree to the <a href="/terms-and-conditions" className="text-decoration-underline" style={{'lineHeight': 'normal'}} target="_blank">Terms & Conditions</a>  
                                    </span>
                                    <input id="terms"
                                        name="termsAccepted"
                                        type="checkbox"
                                        style={{appearance: 'checkbox'}}
                                        checked={this.state.termsAccepted}
                                        onChange={this.handleChange}
                                        valueattr="termsAccepted"
                                    />
                                </div>
                                <button id="card-button" style={{float: 'left'}}
                                        className="text-white btn btn-secondary" onClick={this.submit}
                                        data-secret={this.state.clientSecret}>
                                    Save
                                </button>
                                <div className="AuthorizeNetSeal">
                                    <a href="#" style={{float: 'right'}}
                                       onMouseOver="window.status='http://www.authorize.net/'; return true;"
                                       onMouseOut="window.status=''; return true;" onClick={this.openAuth}>
                                        <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
                                             width="90"
                                             height="72" border="0" alt="Authorize.Net Merchant - Click to Verify"/>
                                    </a>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <UpgradePremiumModal modalVisible={this.state.upgradePremiumModalVisible} toggleModal={() => this.toggleModal('upgradePremiumModalVisible')} history={this.props.history}/>
                </Section>
                <Container className="pt-3 pb-4">
                    <InfoModal
                        title={this.state.title}
                        content={this.state.content}
                        onButtonSubmit={() => this.onButtonGo()}
                        values={this.props}
                        errorMessage={this.props.errorMessage}
                        modalRef={node => (this.loginInformationModal = node)}
                        modalVisible={this.state.loginInformationModalVisible}
                        toggleModal={() => this.toggleModal('loginInformationModalVisible')}
                        noCancel={'noCancel'}/>
                </Container>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({login, stripePaymentInfo}) => ({

    companyId: login.companyId,
    id: stripePaymentInfo.id,
    clientSecret: stripePaymentInfo.clientSecret,
    firstName: stripePaymentInfo.firstName,
    lastName: stripePaymentInfo.lastName,
    companyName: stripePaymentInfo.companyName,
    billingAddress: stripePaymentInfo.billingAddress,
    billingCity: stripePaymentInfo.billingCity,
    billingCountry: stripePaymentInfo.billingCountry,
    billingState: stripePaymentInfo.billingState,
    billingZip: stripePaymentInfo.billingZip,
    email: stripePaymentInfo.email,
    isFreeTrial: login.isFreeTrial,
    isUpgradePremium: login.isUpgradePremium
});
export default connect(mapStateToProps, {getPaymentInfo})(Payments);
