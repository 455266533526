// @flow

import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import Home from './home';
import Search from './search';
import Confirmation from './confirmation';
import Join from './join';
import SubscriberSubscribe from './subscriber/subscribe';
import SubscriberUnauthenticated from './subscriber/unauthenticated';
import SubscriberForgotPassword from './subscriber/forgot';
import SubscriberMessages from './subscriber/messages';
import SubscriberMyAccount from './subscriber/myaccount';
import SubscriberBid from './subscriber/bid';
import SubscriberBidListing from './subscriber/bidlistings';
import SubscriberListing from './subscriber/listing';
import SubscriberListings from './subscriber/listings';
import SubscriberCrashListings from './subscriber/crash';
import SubscriberReports from './subscriber/reports';
import SubscriberCompany from './subscriber/company';
import SubscriberPayments from './subscriber/payments';
import SubscriberReviews from './subscriber/subscriberReviews';
import NotFound from './notfound';
import UserQuoteHistory from './user/userQuoteHistory';
import UserMessages from './user/userMessages';
import UserProfile from './user/userProfile';
import UserSubscribe from './user/userSubscribe';
import AdminReports from './admin/reports';
import subscriberChargeArrears from './subscriber/chargeArrears';
import subscribeConfirm from  './subscriber/subscribeConfirm';
import subscribePayment from "./subscriber/subscribePayment";
import subscriberInit1 from "./subscriber/init";
import subscriberInit2 from "./subscriber/init2";
import subscriberInit3 from "./subscriber/init3";
import subscriberInit4 from "./subscriber/init4";
import subscriberInit5 from "./subscriber/init5";
import termsConditions from './termsConditions/termsConditions';

const Router = () => (
  <BrowserRouter>
    <Wrapper>
      <Switch>
        <Redirect from="/forgot" to={SubscriberForgotPassword.path} exact />
        <Route exact path={Home.path} component={Home.component} />
        <Route path={Search.path} component={Search.component} />
        <Route path={Join.path} component={Join.component} />
        <Route
          exact
          path={Confirmation.path}
          component={Confirmation.component}
        />
        <Route
          exact
          path={SubscriberSubscribe.path}
          component={SubscriberSubscribe.component}
        />
        <Route
            exact
            path={subscribeConfirm.path}
            component={subscribeConfirm.component}
        />
        <Route
            exact
            path={subscribePayment.path}
            component={subscribePayment.component}
        />
        <Route
          exact
          path={SubscriberUnauthenticated.path}
          component={SubscriberUnauthenticated.component}
        />
        <Route
          exact
          path={SubscriberForgotPassword.path}
          component={SubscriberForgotPassword.component}
        />
        <Route
          exact
          path={SubscriberMyAccount.path}
          component={SubscriberMyAccount.component}
        />
        <Route
          exact
          path={SubscriberMessages.path}
          component={SubscriberMessages.component}
        />
        <Route
          exact
          path={SubscriberBid.path}
          component={SubscriberBid.component}
        />
        <Route
          exact
          path={SubscriberListing.path}
          component={SubscriberListing.component}
        />
        <Route
          exact
          path={SubscriberListings.path}
          component={SubscriberListings.component}
        />
        <Route
          exact
          path={SubscriberCrashListings.path}
          component={SubscriberCrashListings.component}
        />
        <Route
          exact
          path={SubscriberReports.path}
          component={SubscriberReports.component}
        />
        <Route
          exact
          path={SubscriberCompany.path}
          component={SubscriberCompany.component}
        />
        <Route
          exact
          path={SubscriberPayments.path}
          component={SubscriberPayments.component}
        />
        <Route
          exact
          path={SubscriberBidListing.path}
          component={SubscriberBidListing.component}
        />
        <Route
          exact
          path={SubscriberReviews.path}
          component={SubscriberReviews.component}
        />
        <Route exact path={NotFound.path} component={NotFound.component} />
        <Route
          exact
          path={UserQuoteHistory.path}
          component={UserQuoteHistory.component}
        />
        <Route
          exact
          path={UserMessages.path}
          component={UserMessages.component}
        />
        <Route
          exact
          path={UserProfile.path}
          component={UserProfile.component}
        />
        <Route
          exact
          path={UserSubscribe.path}
          component={UserSubscribe.component}
        />
        <Route
          exact
          path={AdminReports.path}
          component={AdminReports.component}
        />
        <Route
          exact
          path={subscriberInit3.path}
          component={subscriberInit3.component}
      />
        <Route
            exact
            path={subscriberInit1.path}
            component={subscriberInit1.component}
        />
          <Route
              exact
              path={subscriberInit2.path}
              component={subscriberInit2.component}
          />
        <Route
          exact
          path={subscriberInit4.path}
          component={subscriberInit4.component}
        />
        <Route
          exact
          path={subscriberInit5.path}
          component={subscriberInit5.component}
        />
        <Route
          exact
          path={termsConditions.path}
          component={termsConditions.component}
        />

        <Route path={subscriberChargeArrears.path} component={subscriberChargeArrears.component} />
        <Redirect to="/" />
      </Switch>
    </Wrapper>
  </BrowserRouter>
);

export default Router;
