import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Alert,
    Button,
    Col,
    Container, Input,
    Jumbotron,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from 'reactstrap';
import {Footer, LargeButton, Section} from '../../../components';
import {
    getPaymentInfo,
    fetchListings
} from '../../../actions/subscriber/init5';

import {savePaymentInfo, savePaymentInfoAndPay} from '../../../services/subscriber/init5';
import $ from 'jquery';
import glamorous from "glamorous";

import { paymentMethods } from '../../../assets/img';


class Init5 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            accountStatus: "cc_recurring",
            ccRecurring: "Recurring 1st",
            paymentOption: "1",

            id: "",
            companyId: "",
            cardNumber: "",
            cardMonth: "01",
            cardYear: "2025",
            cardCvv: "",

            firstName: "",
            lastName: "",
            billingAddress: "",
            billingCity: "",
            billingCountry: "",
            billingState: "",
            billingZip: "",
            email: "",
            termsAccepted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    componentWillMount = () => {

    };

    componentDidMount = () => {

        if(!$("#my-account").hasClass("disabled"))
            $("#my-account").addClass("disabled")

        this.props.getPaymentInfo().then(() => {
            this.setState({
                accountStatus: "cc_recurring",
                ccRecurring: "Recurring 1st",
                paymentOption: this.props.paymentOption ? this.props.paymentOption : "1",
                cardNumber: this.props.cardNumber,
                cardCvv: this.props.cardCvv,
                cardMonth: this.props.cardMonth ? this.props.cardMonth : "01",
                cardYear: this.props.cardYear ? this.props.cardYear : "2025",
                firstName: this.props.firstName,
                lastName: this.props.lastName,
                billingAddress: this.props.billingAddress,
                billingCity: this.props.billingCity,
                billingState: this.props.billingState ? this.props.billingState : "AL",
                billingCountry: this.props.billingCountry ? this.props.billingCountry : "USA",
                billingZip: this.props.billingZip,
                email: this.props.email
            });

            this.props.fetchListings(this.props.companyId, this.state.paymentOption);
        });
    };

    onSaveButtonClick = () => {

        if (!$("#save-btn").hasClass("loading") && this.validForm()) {

            let data = {
                cardNumber: this.state.cardNumber
                , cardMonth: this.state.cardMonth
                , cardYear: this.state.cardYear
                , cardCvv: this.state.cardCvv
                , firstName: this.state.firstName
                , lastName: this.state.lastName
                , billingAddress: this.state.billingAddress
                , billingCity: this.state.billingCity
                , billingCountry: this.state.billingCountry
                , billingState: this.state.billingState
                , billingZip: this.state.billingZip
                , email: this.state.email
                , companyId: this.props.companyId
                , accountStatus: this.state.accountStatus
                , ccRecurring: this.state.ccRecurring
                , paymentOption: this.state.paymentOption
            };

            $("#save-btn").addClass("loading");
            $("#pay-btn").addClass("loading");
            savePaymentInfo(data).then((response) => {
                if (response.data.status.code === 0 && response.data.result == true) {

                    $("#my-account").removeClass("disabled")
                    this.props.history.push('/subscribers/crash');
                } else {
                    alert("Your credit card has been declined for authorization, " +
                        "please provide new credit card. Should you continue to have trouble saving your credit card, " +
                        "Please call us at 1-703-838-2955 and we will be happy to help you!");
                }
                $("#save-btn").removeClass("loading");
                $("#pay-btn").removeClass("loading");
            });
        }
    };

    onPayButtonClick = () => {

        if (!$("#pay-btn").hasClass("loading") && this.validForm()) {
            let data = {
                cardNumber: this.state.cardNumber
                , cardMonth: this.state.cardMonth
                , cardYear: this.state.cardYear
                , cardCvv: this.state.cardCvv
                , firstName: this.state.firstName
                , lastName: this.state.lastName
                , billingAddress: this.state.billingAddress
                , billingCity: this.state.billingCity
                , billingCountry: this.state.billingCountry
                , billingState: this.state.billingState
                , billingZip: this.state.billingZip
                , email: this.state.email
                , companyId: this.props.companyId
                , accountStatus: this.state.accountStatus
                , ccRecurring: this.state.ccRecurring
                , paymentOption: this.state.paymentOption
            };

            $("#save-btn").addClass("loading");
            $("#pay-btn").addClass("loading");
            savePaymentInfoAndPay(data).then((response) => {
                if (response.data.status.code === 0) {
                    $("#my-account").removeClass("disabled")
                    this.props.history.push('/subscribers/listings');
                } else {

                    alert("Your credit card has been declined for authorization, " +
                        "please provide new credit card. Should you continue to have trouble saving your credit card, " +
                        "Please call us at 1-703-838-2955 and we will be happy to help you!");
                }
                $("#save-btn").removeClass("loading");
                $("#pay-btn").removeClass("loading")
            });
        }
    };

    validForm = () => {
        let flag = true;
        if (!!!this.state.cardNumber) {
            document.getElementById("card-number").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.cardCvv) {
            document.getElementById("card-cvv").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.cardMonth) {
            document.getElementById("card-month").parentNode.parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.cardYear) {
            document.getElementById("card-year").parentNode.parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.firstName) {
            document.getElementById("first-name").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.lastName) {
            document.getElementById("last-name").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.billingAddress) {
            document.getElementById("billing-address").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.billingCity) {
            document.getElementById("billing-city").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.billingState) {
            document.getElementById("billing-state").parentNode.parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.billingCountry) {
            document.getElementById("billing-country").parentNode.parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.billingZip) {
            document.getElementById("billing-zip").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!!!this.state.email) {
            document.getElementById("email").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        if (!this.state.termsAccepted) {
            document.getElementById("terms").parentNode.lastChild.style.display = "block";
            flag = false;
        }
        return flag;
    };

    resetForm = (attr, value) => {
        if (attr === "cardNumber" && !!value) {
            document.getElementById("card-number").parentNode.lastChild.style.display = "none";
        }
        if (attr === "cardCvv" && !!value) {
            document.getElementById("card-cvv").parentNode.lastChild.style.display = "none";
        }
        if (attr === "cardMonth" && !!value) {
            document.getElementById("card-month").parentNode.parentNode.lastChild.style.display = "none";
        }
        if (attr === "cardYear" && !!value) {
            document.getElementById("card-year").parentNode.parentNode.lastChild.style.display = "none";
        }
        if (attr === "firstName" && !!value) {
            document.getElementById("first-name").parentNode.lastChild.style.display = "none";
        }
        if (attr === "lastName" && !!value) {
            document.getElementById("last-name").parentNode.lastChild.style.display = "none";
        }
        if (attr === "billingAddress" && !!value) {
            document.getElementById("billing-address").parentNode.lastChild.style.display = "none";
        }
        if (attr === "billingCity" && !!value) {
            document.getElementById("billing-city").parentNode.lastChild.style.display = "none";
        }
        if (attr === "billingState" && !!value) {
            document.getElementById("billing-state").parentNode.parentNode.lastChild.style.display = "none";
        }
        if (attr === "billingCountry" && !!value) {
            document.getElementById("billing-country").parentNode.parentNode.lastChild.style.display = "none";
        }
        if (attr === "billingZip" && !!value) {
            document.getElementById("billing-zip").parentNode.lastChild.style.display = "none";
        }
        if (attr === "email" && !!value) {
            document.getElementById("email").parentNode.lastChild.style.display = "none";
        }
        if (attr === "termsAccepted" && !!value) {
            document.getElementById("terms").parentNode.lastChild.style.display = "none";
        }
    };

    handleChange(event) {
        const parentDom = $(event.target.parentNode);
        let attr = event.target.getAttribute("valueattr");
        let state = {};
        
        // 获取 checkbox 的状态
        if (event.target.type === 'checkbox') {
            state[attr] = event.target.checked; // 使用 checked 属性
        } else {
            state[attr] = event.target.value;
        }

        this.setState(state);

        if (event.target.value !== "") {
            event.target.style.backgroundColor = "white";
            if ($(event.target).is("select") && parentDom.find("em")) {
                parentDom.find("em").html(event.target.options[event.target.selectedIndex].text);
                parentDom.find("em").addClass("done");
            }
        }

        this.resetForm(attr, event.target.value);

        if (attr === "paymentOption") {
            $("#metro-loading").addClass("loading");
            this.props.fetchListings(this.props.companyId, event.target.value).then(() => {
                $("#metro-loading").removeClass("loading");
            });
        }
    };

    numberChange(event) {

        event.target.value = event.target.value.replace(/[^\d]/g,'');
    };

    handleCountryChange(event) {
        const parentDom = $(event.target.parentNode);
        parentDom.find("em").html(event.target.options[event.target.selectedIndex].text);
        parentDom.find("em").addClass("done");

        let country = event.target.value;
        this.setState({"billingCountry": country});
        let options = document.getElementById('billing-state').childNodes;
        let hasSelected = false;
        for (let i = 0; i < options.length; i++) {
            if (options[i].getAttribute("country") === country) {
                if (!hasSelected) {
                    this.setState({"billingState": options[i].value});

                    const parentDom = $(document.getElementById('billing-state').parentNode);
                    parentDom.find("em").html(options[i].value);
                    parentDom.find("em").addClass("done");

                    options[i].selected = true;
                    hasSelected = true;
                }
                options[i].style.display = "";
            } else {
                options[i].selected = false;
                options[i].style.display = "none";
            }
        }
        this.resetForm("billingCountry", event.target.value);
    };

    renderPaymentOption = () => {
        const paymentOption = this.props.paymentOption ? this.props.paymentOption : "1";
        let paymentOptionText = null;
        if (paymentOption === "1") {
            paymentOptionText = "Monthly";
        } else if (paymentOption === "2") {
            paymentOptionText = "Quarterly";
        } else if (paymentOption === "3") {
            paymentOptionText = "Six Months";
        } else if (paymentOption === "4") {
            paymentOptionText = "Annual";
        }
        return (<div className="select_content">
            <em className={paymentOptionText === null ? "" : "done"}>{paymentOptionText === null ? "Payment Option" : paymentOptionText}</em>
            <select id="payment-option"
                    valueattr="paymentOption"
                    value={this.state.paymentOption}
                    onChange={this.handleChange}>
                <option value="1">Monthly</option>
                <option value="2">Quarterly</option>
                <option value="3">Six Months</option>
                <option value="4">Annual</option>
            </select>
            <div className="idrop"></div>
        </div>)
    };

    renderMonth = () => {
        const cardMonth = this.props.cardMonth ? this.props.cardMonth : "01";
        return (<div className="select_content">
            <em className={cardMonth ? "done" : ""}>{cardMonth ? cardMonth : "Month"}</em>
            <select id="card-month"
                    valueattr="cardMonth"
                    value={this.state.cardMonth}
                    onChange={this.handleChange}>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
            </select>
            <div className="idrop"></div>
        </div>)
    };

    renderYear = () => {
        const cardYear = this.props.cardYear ? this.props.cardYear : "2025";
        return (<div className="select_content">
            <em className={cardYear ? "done" : ""}>{cardYear ? cardYear : "Year"}</em>
            <select id="card-year"
                    valueattr="cardYear"
                    value={this.state.cardYear}
                    onChange={this.handleChange}>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                <option value="2031">2031</option>
                <option value="2032">2032</option>
                <option value="2033">2033</option>
                <option value="2034">2034</option>
                <option value="2035">2035</option>
                <option value="2036">2036</option>
                <option value="2037">2037</option>
                <option value="2038">2038</option>
                <option value="2039">2039</option>
                <option value="2040">2040</option>
            </select>
            <div className="idrop"></div>
        </div>)
    };

    renderState = () => {
        const country = this.props.billingCountry ? this.props.billingCountry : "USA";
        const billingState = this.props.billingState ? this.props.billingState : "AL";
        return (<div className="select_content">
            <em className={billingState ? "done" : ""}>{billingState ? billingState : "Billing State"}</em>
            <select id="billing-state"
                    valueattr="billingState"
                    value={this.state.billingState}
                    onChange={this.handleChange}>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="Ontario">Ontario
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="Quebec">Quebec
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA"
                        value="British Columbia">British Columbia
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="Alberta">Alberta
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="Manitoba">Manitoba
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA"
                        value="Saskatchewan">Saskatchewan
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="Nova Scotia">Nova
                    Scotia
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="New Brunswick">New
                    Brunswick
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA"
                        value="Newfoundland and Labrador">Newfoundland and Labrador
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA"
                        value="Prince Edward Island">Prince Edward Island
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA"
                        value="Northwest Territories">Northwest Territories
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="Nunavut">Nunavut
                </option>
                <option style={{"display": (country !== "CA" ? "none" : "")}} country="CA" value="Yukon">Yukon</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="AL">AL</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="AK">AK</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="AS">AS</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="AZ">AZ</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="AR">AR</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="CA">CA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="CO">CO</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="CT">CT</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="DE">DE</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="DC">DC</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="FM">FM</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="FL">FL</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="GA">GA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="GU">GU</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="HI">HI</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="ID">ID</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="IL">IL</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="IN">IN</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="IA">IA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="KS">KS</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="KY">KY</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="LA">LA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="ME">ME</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MH">MH</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MD">MD</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MA">MA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MI">MI</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MN">MN</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MS">MS</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MO">MO</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MT">MT</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="NE">NE</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="NV">NV</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="NH">NH</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="NJ">NJ</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="NM">NM</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="NY">NY</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="NC">NC</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="ND">ND</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="MP">MP</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="OH">OH</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="OK">OK</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="OR">OR</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="PW">PW</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="PA">PA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="PR">PR</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="RI">RI</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="SC">SC</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="SD">SD</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="TN">TN</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="TX">TX</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="UT">UT</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="VT">VT</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="VI">VI</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="VA">VA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="WA">WA</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="WV">WV</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="WI">WI</option>
                <option style={{"display": (country !== "USA" ? "none" : "")}} country="USA" value="WY">WY</option>
            </select>
            <div className="idrop"></div>
        </div>)
    };

    renderCountry = () => {
        const billingCountry = this.props.billingCountry ? this.props.billingCountry : "USA";
        return (<div className="select_content">
            <em className={billingCountry ? "done" : ""}>{billingCountry ? billingCountry : "Billing Country"}</em>
            <select
                id="billing-country"
                onChange={this.handleCountryChange}
                value={this.state.billingCountry}
                valueAttr="billingCountry">
                <option value="USA">USA</option>
                <option value="CA">CA</option>
            </select>
            <div className="idrop"></div>
        </div>)
    };

    renderMetroList = () => {

        const listings = this.props.listings;
        if (!listings)
            return null;

        const paymentOption = this.state.paymentOption ? this.state.paymentOption : "1";
        let paymentOptionText = null;
        if (paymentOption === "1") {
            paymentOptionText = "Monthly";
        } else if (paymentOption === "2") {
            paymentOptionText = "Quarterly";
        } else if (paymentOption === "3") {
            paymentOptionText = "Six Months";
        } else if (paymentOption === "4") {
            paymentOptionText = "Annual";
        }

        return listings.map(result => (
            <li>
                <span>{result.metroName}</span>&nbsp;<em>${result.costValue}/{paymentOptionText}</em>
            </li>
        ));
    };

    renderMetroListingTotal = () => {

        const paymentOption = this.state.paymentOption ? this.state.paymentOption : "1";
        let paymentOptionText = null;
        if (paymentOption === "1") {
            paymentOptionText = "Monthly";
        } else if (paymentOption === "2") {
            paymentOptionText = "Quarterly";
        } else if (paymentOption === "3") {
            paymentOptionText = "Six Months";
        } else if (paymentOption === "4") {
            paymentOptionText = "Annual";
        }

        return (
            <ul className="ss_total">
                <li>
                    <span>Total Billing</span>&nbsp;<em>${this.props.totalBillingAmount}/{paymentOptionText}</em>
                </li>
                <li>
                    <span>Prorated Billing</span>&nbsp;<em>${this.props.proratedBillingAmount}/{paymentOptionText}</em>
                </li>
            </ul>
        )
    };

    renderMetroListingTip = () => (
        <div className="content_block">
            <p className="text_info">You will be charged a prorated billing amount
                between <em>{this.props.today}</em> and <em>{this.props.nextRecurringDate}</em>.</p>
            <p className="text_info">Renewal Date: <em>{this.props.nextRecurringDate}</em></p>
        </div>
    );

    render() {
        return (
            <div className="pt-5">
                <Section>
                    <section className="cus_init">
                        <div className="wrap">
                            <h3 className="section_title">Setup Progress</h3>
                            <ul className="init_steps">
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">1</div>
                                        <div className="text">Profile Information</div>
                                    </div>
                                </li>
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">2</div>
                                        <div className="text">Fleet Information</div>
                                    </div>
                                </li>
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">3</div>
                                        <div className="text">Add Metros</div>
                                    </div>
                                </li>
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">4</div>
                                        <div className="text">Bid for Placement</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">5</div>
                                        <div className="text">Payment</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="content_grid content_lr_73">
                                <div className="content">
                                    <div className="nr_content">
                                        <div className="content_block">
                                            <h3 className="block_title">Payment Information</h3>
                                            <div className="form_box">
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">CC Recurring</label>
                                                    <input type="text" readOnly="readonly" value="Recurring 1st"/>
                                                </div>
                                            </div>
                                            <div className="form_box">
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Payment Option</label>
                                                    {this.renderPaymentOption()}
                                                    <div className="tip"></div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Card Number</label>
                                                    <input id="card-number"
                                                           type="text"
                                                           maxLength={'19'}
                                                           minLength={'13'}
                                                           valueattr="cardNumber"
                                                           placeholder="Card Number"
                                                           value={this.state.cardNumber}
                                                           onChange={this.handleChange}
                                                           onKeyUp={this.numberChange}/>
                                                    <div className="tip">Card Number is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Security Code(CVV)</label>
                                                    <input id="card-cvv" type="text"
                                                           valueattr="cardCvv"
                                                           maxLength={'4'}
                                                           placeholder="Security Code"
                                                           value={this.state.cardCvv}
                                                           onChange={this.handleChange}
                                                           onKeyUp={this.numberChange}/>
                                                    <div className="tip">Security Code(CVV) is required.</div>
                                                </div>
                                                <div className="input_box quarter necessary">
                                                    <label htmlFor="">Expiration date</label>
                                                    {this.renderMonth()}
                                                    <div className="tip">Month is required.</div>
                                                </div>
                                                <div className="input_box quarter necessary">
                                                    {this.renderYear()}
                                                    <div className="tip">Year is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">First Name</label>
                                                    <input id="first-name"
                                                           type="text"
                                                           valueattr="firstName"
                                                           value={this.state.firstName}
                                                           placeholder="First Name"
                                                           onChange={this.handleChange}/>
                                                    <div className="tip">First Name is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Last Name</label>
                                                    <input id="last-name"
                                                           type="text"
                                                           valueattr="lastName"
                                                           value={this.state.lastName}
                                                           placeholder="Last Name"
                                                           onChange={this.handleChange}/>
                                                    <div className="tip">Last Name is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Billing Address</label>
                                                    <input id="billing-address"
                                                           type="text"
                                                           valueattr="billingAddress"
                                                           placeholder="Billing Address"
                                                           value={this.state.billingAddress}
                                                           onChange={this.handleChange}/>
                                                    <div className="tip">Billing Address is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Billing City</label>
                                                    <input id="billing-city"
                                                           type="text"
                                                           valueattr="billingCity"
                                                           placeholder="Billing City"
                                                           value={this.state.billingCity}
                                                           onChange={this.handleChange}/>
                                                    <div className="tip">Billing City is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Billing State</label>
                                                    {this.renderState()}
                                                    <div className="tip">Billing State is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Billing Country</label>
                                                    {this.renderCountry()}
                                                    <div className="tip">Billing Country is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Billing Zip</label>
                                                    <input id="billing-zip"
                                                           type="text"
                                                           valueattr="billingZip"
                                                           value={this.state.billingZip}
                                                           placeholder="Billing Zip"
                                                           onChange={this.handleChange}/>
                                                    <div className="tip">Billing Zip is required.</div>
                                                </div>
                                                <div className="input_box half necessary">
                                                    <label htmlFor="">Email Address</label>
                                                    <input id="email"
                                                           type="text"
                                                           valueattr="email"
                                                           value={this.state.email}
                                                           placeholder="Email Address"
                                                           onChange={this.handleChange}/>
                                                    <div className="tip">Email Address is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{"display": "flex", "alignItems": "center", "marginBottom": "20px"}}>
                                            <div style={{"marginRight": "10px"}}>Currently supporting:</div>
                                            <img src={paymentMethods} style={{"height": "30px", "width": "auto", "objectFit": "contain"}} alt="Payment Methods" />
                                        </div>
                                        <div className="content_block">
                                            <h3 className="block_title">Terms and Conditions</h3>
                                            <div className="form_box">
                                                <div className="input_box necessary">
                                                    <span className="pr-3">
                                                        I read and agree to the <a href="/terms-and-conditions" className="text-decoration-underline" style={{ lineHeight: 'normal' }} target="_blank">Terms & Conditions</a>
                                                    </span>
                                                    <input
                                                        id="terms"
                                                        name="termsAccepted"
                                                        type="checkbox"
                                                        style={{ appearance: 'checkbox' }}
                                                        checked={this.state.termsAccepted}
                                                        onChange={this.handleChange}
                                                        valueattr="termsAccepted"
                                                    />
                                                    <div className="tip">Terms and conditions is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <div id="metro-loading" className="nr_content step_summary">
                                        <div className="content_block">
                                            <h3 className="block_title">Metro Listing</h3>
                                            <ul className="ss_list">
                                                {this.renderMetroList()}
                                            </ul>
                                            {this.renderMetroListingTotal()}
                                        </div>
                                        {this.renderMetroListingTip()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div id="save-btn" onClick={() => this.onSaveButtonClick()} className="button line large">
                                <span>Just Save<br/>Payment Information</span>
                            </div>
                            <div id="pay-btn" onClick={() => this.onPayButtonClick()} className="button large">
                                <span>Pay</span>
                            </div>
                        </div>
                    </section>
                </Section>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({login, init5}) => ({
    companyId: login.companyId,
    accountStatus: init5.accountStatus,
    ccRecurring: init5.ccRecurring,
    paymentOption: init5.paymentOption,
    firstName: init5.firstName,
    lastName: init5.lastName,
    cardNumber: init5.cardNumber,
    cardCvv: init5.cardCvv,
    companyName: init5.companyName,
    cardMonth: init5.cardMonth,
    cardYear: init5.cardYear,
    billingAddress: init5.billingAddress,
    billingZip: init5.billingZip,
    billingCity: init5.billingCity,
    billingState: init5.billingState,
    billingCountry: init5.billingCountry,
    email: init5.email,
    listings: init5.listings,
    locations: init5.locations,
    totals: init5.totals,
    proratedBillingAmount: init5.proratedBillingAmount,
    totalBillingAmount: init5.totalBillingAmount,
    nextRecurringDate: init5.nextRecurringDate,
    today: init5.today
});
export default connect(mapStateToProps, {
    getPaymentInfo,
    fetchListings
})(Init5);
